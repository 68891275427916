<template>
  <div>
      <v-card class="card-show">

      </v-card>
  </div>
</template>

<script>
export default {
  name: "InquiryView",
  data() {
    return {}
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>