<template>
  <div class="main-margin" v-if="!loading">
    <v-card class="main-padding" color="white">
      <v-card-title>
        ویرایش کاربر
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn width="200px" class="main-button" @click="$router.push('/user/index')">
              <v-icon class="main-icon">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="user.name" label="نام کاربر" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="user.mobile" label="شماره همراه کاربر" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="user.email" type="email" label="ایمیل" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="user.password" label="رمز عبور کاربر" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="user.password_confirmation" label=" تکرار رمز عبور کاربر" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="user.postal_code" label="کد پستی کاربر" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="user.national_code" label="کد ملی کاربر" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field label="آواتار"
                          @click="avatarDialog = true" prepend-icon="mdi-plus"
                          color="black"
                          v-model="user.avatar"></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-select color="black" v-model="user.status" :items="[{label:'فعال',value:'active'},{label:'غیر فعال',value:'inactive'},{label:'غیر مجاز',value:'banned'}]" item-text="label"
                      label="وضعیت کاربر انتخاب کنید" item-value="value"></v-select>
          </v-col>

          <v-col cols="3">
            <v-autocomplete color="black" v-model="user.state_id"
                            :items="states"
                            item-text="label"
                            label="استان را انتخاب کنید" item-value="id" @change="getCities"></v-autocomplete>
          </v-col>

          <v-col cols="3" v-if="user.state_id">
            <v-autocomplete color="black" v-model="user.city_id"
                            :items="cities"
                            item-text="label"
                            label="شهر را انتخاب کنید" item-value="id"></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-text-field v-model="user.address" label="آدرس کامل کاربر" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="edit()" class="letter-spacing-normal white--text main-button" width="200px">ویرایش
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="avatarDialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader" @closeUploadFileDialog="avatarDialog = false"
                     @changeFile="user.avatar = $event"></drag-and-drop>
    </v-dialog>
  </div>
</template>

<script>

import DragAndDrop from "@/components/UploadFile/DragAndDrop.vue";

export default {
  name: "UserEditView",
  components: {DragAndDrop},
  data() {
    return {
      states: [],
      cities: [],
      loading: true,
      avatarDialog: false,
      user: {
        name: null,
        state_id: null,
        city_id: null,
        mobile: null,
        national_code: null,
        avatar: null,
        email: null,
        password: null,
        password_confirmation:null,
        status: null,
        address: null,
        postal_code: null,
      },
    }
  },
  mounted() {
    this.getEntity()
    this.getStates()
  },
  methods: {
    getCities() {
      window.axios.get('admin/city/state/index/' + this.user.state_id).then((res) => {
        this.cities = res.data.data
      })
    },
    getStates() {
      window.axios.get('admin/state/index?per_page=100').then((res) => {
        this.states = res.data.data.data
      })
    },
    getEntity() {
      window.axios.get('admin/user/get/' + this.$route.params.id).then((res) => {
        this.user = res.data.data
        this.loading = false
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#F1416C')
      })
    },
    edit() {
      window.axios.post('admin/user/update/' + this.$route.params.id, {
        name: this.user.name,
        city_id: this.user.city_id,
        state_id: this.user.state_id,
        mobile: this.user.mobile,
        national_code: this.user.national_code,
        avatar: this.user.avatar,
        email: this.user.email,
        password: this.user.password,
        password_confirmation: this.user.password_confirmation,
        status: this.user.status,
        address: this.user.address,
        postal_code: this.user.postal_code,
      }).then((res) => {
        this.openSnackbar(res.data.message, '#413b3b')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err, '#F1416C')
        } else if (err.response.status === 400 || err.response.status === 404) {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  }
}
</script>

<style>
.main-margin {
  margin: 2vh 6vw 2vh 2vw;
}

.main-padding {
  padding: 1vh 2vw;
}

.text-dialog-title {
  font-size: 18px;
  font-weight: 800;
}

.letter-spacing-normal {
  letter-spacing: normal;
}
</style>