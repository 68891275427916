<template>
  <div v-if="!loading">
    <v-card class="card-show" color="white">
      <v-card-title>
        ویرایش واحد مالی
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn width="200px" class="main-button" @click="$router.push('/currency/index')">
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="currency.name" label="نام" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="currency.label" label="لیبل" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="currency.abbr" label="اختصار رسمی" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field type="number" v-model="currency.difference" label="تفاوت ریالی"
                          placeholder="+1000 یا -1000 یعنی قیمت زنده به علاوه یا منهای ۱۰۰۰ ریال"
                          hint="با پر کردن مثبت این فیلد قیمت لحظه ای به علاوه این قیمت به کاربر نمایش داده می شود و محاسبات در اساس آن انجام خواهد شد. و با پر کردن منفی این فیلد دقیقا برعکس."
                          color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-checkbox color="black" label="فعال؟" v-model="currency.is_active" :true-value="1" :false-value="0"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-checkbox color="black" label="نمایش در سایت؟" v-model="currency.is_visible" :true-value="1"
                        :false-value="0"></v-checkbox>
          </v-col>
          <v-col cols="3">
            <v-text-field label="فایل آیکون(نمایش در سایت)"
                          @click="iconDialog = true" prepend-icon="mdi-plus"
                          color="black"
                          v-model="currency.icon"></v-text-field>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="edit()" class="letter-spacing-normal white--text main-button" width="200px">ویرایش
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="iconDialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader" @closeUploadFileDialog="iconDialog = false"
                     @changeFile="currency.icon = $event"></drag-and-drop>
    </v-dialog>
  </div>
</template>

<script>

import DragAndDrop from "@/components/UploadFile/DragAndDrop.vue";

export default {
  name: "CurrencyEditView",
  components: {DragAndDrop},
  data() {
    return {
      loading: true,
      iconDialog: false,
      currency: {
        name: null,
        label: null,
        is_active: null,
        is_visible: null,
        abbr: null,
        difference: null,
        icon: null,
      },
    }
  },
  mounted() {
    this.getEntity()
  },
  methods: {
    getEntity() {
      window.axios.get('admin/currency/get/' + this.$route.params.id).then((res) => {
        this.currency = res.data.data
        this.loading = false
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#F1416C')
      })
    },
    edit() {
      window.axios.post('admin/currency/update/' + this.$route.params.id, {
        name: this.currency.name,
        label: this.currency.label,
        is_active: this.currency.is_active,
        is_visible: this.currency.is_visible,
        abbr: this.currency.abbr,
        difference: this.currency.difference,
        icon: this.currency.icon,
      }).then((res) => {
        this.openSnackbar(res.data.message, '#413b3b')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err, '#F1416C')
        } else if (err.response.status === 400 || err.response.status === 404) {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  }
}
</script>

<style scoped>
.main-margin {
  margin: 2vh 6vw 2vh 2vw;
}

.main-padding {
  padding: 1vh 2vw;
}

.text-dialog-title {
  font-size: 18px;
  font-weight: 800;
}

.letter-spacing-normal {
  letter-spacing: normal;
}
</style>