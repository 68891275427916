<template>
  <div v-if="!loading">
    <v-card class="card-show">
      <v-card-title>
        نمایش جزییات و تاریخچه واحد مالی
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn width="200px" class="main-button" @click="$router.push('/history/index')">
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-card class="card-show">
              <v-card-title>{{ history.label }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="3">
                    <v-card-text>نام سیستمی : {{ history.name }}</v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>
                      نوع :
                      <span v-if="history.type === 'gold'">
                        طلا
                      </span>
                      <span v-if="history.type === 'currency'">
                        ارز
                      </span>
                    </v-card-text>
                  </v-col>

                  <v-col cols="3">
                    <v-card-text>
                      آخرین زمان به روز رسانی :
                      {{ history.last_check_fa }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>
                      مخفف :
                      {{ history.abbr }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>
                      تفاوت درخواستی قیمتی :
                      <div v-if="history.difference < 0" class="error--text">{{ history.difference }}</div>
                      <div v-else class="green--text">{{ history.difference }}</div>
                    </v-card-text>
                  </v-col>

                  <v-col cols="3">
                    <v-card-text>
                      قیمت زنده :
                      {{ formatNumber(history.real_price) }} ریال
                    </v-card-text>
                  </v-col>

                  <v-col cols="3">
                    <v-card-text>
                      قیمت محاسبه شده :
                      {{ formatNumber(history.calculated_price) }} ریال
                    </v-card-text>
                  </v-col>
                  <img width="150px" height="150px" alt="coin" :src="history.icon">
                  <v-col>
                    <v-card-text>
                      آخرین ویرایش توسط :
                      {{ history.admin_name }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>تاریخ ثبت نام فروشنده: {{ history.created_at_fa }}</v-card-text>
                  </v-col>
                  <v-col cols="2">
                    <v-card-text>
                      سیستمی است؟ :
                      <span v-if="history.is_hard">
                          <v-icon color="success">mdi-check</v-icon>
                        </span>
                      <span v-else>
                          <v-icon color="error">mdi-close</v-icon>
                        </span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="2">
                    <v-card-text>
                      فعال است؟ :
                      <span v-if="history.is_active">
                          <v-icon color="success">mdi-check</v-icon>
                        </span>
                      <span v-else>
                          <v-icon color="error">mdi-close</v-icon>
                        </span>
                    </v-card-text>
                  </v-col>
                  <v-col cols="2">
                    <v-card-text>
                      قابل مشاهده است؟ :
                      <span v-if="history.is_visible">
                          <v-icon color="success">mdi-check</v-icon>
                        </span>
                      <span v-else>
                          <v-icon color="error">mdi-close</v-icon>
                        </span>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="card-show mt-6">
              <v-card-title>نمودار تغییر قیمت</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12" class="chart-container">
                    <FinanceLineChart :dataset="chart"></FinanceLineChart>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import FinanceLineChart from "@/components/Charts/FinanceLineChart.vue";


export default {
  name: "CurrencyHistoryView",
  components: {
    FinanceLineChart
  },
  data() {
    return {
      loading: true,
      history: {},
      chart: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#050000',
            borderColor: '#ea050d',
            data: []
          },
        ]
      },
    }
  },
  mounted() {
    this.getEntity()
  },
  methods: {
    formatNumber(value) {
      const numericValue = parseFloat(value);
      return numericValue.toLocaleString();
    },
    getEntity() {
      window.axios.get('admin/currency/history/' + this.$route.params.id).then((res) => {
        this.history = res.data.data.currency
        this.chart.labels = res.data.data.chart.labels
        this.chart.datasets[0].data = res.data.data.chart.data
        this.chart.datasets[0].label = res.data.data.chart.name
        this.loading = false
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#F1416C')
      })
    },
  }
}
</script>

<style>
.card-show {
  border-radius: 10px;
  border-color: #FFD700 !important;
  border-style: solid !important;
}

.chart-container {
  height: 300px;
}
</style>