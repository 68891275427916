<template>
  <div v-if="!loading">
    <v-card class="card-show">
      <v-card-title>
        نمایش جزییات تیکت
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn width="200px" class="main-button" @click="$router.push('/ticket/index')">
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-card class="card-show">
              <v-card-title>{{ ticket.title }}</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="2">
                    <v-card-text>شناسه تیکت: {{ ticket.id }}</v-card-text>
                  </v-col>
                  <v-col cols="2">
                    <v-card-text>کد تیکت: {{ ticket.code }}</v-card-text>
                  </v-col>
                  <v-col cols="2">
                    <v-card-text>اولویت تیکت: {{ ticket.priority }}</v-card-text>
                  </v-col>
                  <v-col cols="2">
                    <v-card-text>بخش تیکت: {{ ticket.section }}</v-card-text>
                  </v-col>
                  <v-col cols="2">
                    <v-card-text>مشاهده شده؟: {{ ticket.is_seen }}</v-card-text>
                  </v-col>
                  <v-col cols="3" v-if="ticket.crm_name">
                    <v-card-text>مدیر پاسخگو: {{ ticket.crm_name }}</v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>وضعیت: {{ ticket.status }}</v-card-text>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text>تاریخ ثبت نام کاربر: {{ ticket.created_at_fa }}</v-card-text>
                  </v-col>
                  <v-col cols="12">
                    {{ ticket.content }}
                  </v-col>
                  <v-col cols="12" v-if="ticket.children_recursive">
                    <TicketComponent :children="ticket.children_recursive"/>
                  </v-col>
                  <v-col cols="12" class="text-center">
                    <v-textarea color="black" label="متن پاسخ را بنویسید." v-model="response.content">
                    </v-textarea>
                    <v-btn width="200px" class="main-button" @click="answer">
                      ارسال پاسخ
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="ticket.user_name" class="card-show mt-4">
              <v-card-text>
                <v-row>
                  <v-col cols="3">
                    کاربر: {{ ticket.user.name }}
                  </v-col>
                  <v-col cols="3">
                    شماره همراه : {{ ticket.user.mobile }}
                  </v-col>
                  <v-col cols="3">
                    ایمیل : {{ ticket.user.email }}
                  </v-col>
                  <v-col cols="3">
                    کد ملی : {{ ticket.user.national_code }}
                  </v-col>
                  <v-col cols="3">
                    وضعیت : {{ ticket.user.status }}
                  </v-col>
                  <v-col cols="3">
                    وضعیت تطابق کد ملی و شماره همراه : {{ ticket.user.match_mobile_with_national_code }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="ticket.admin_name" class="card-show mt-4">
              <v-card-text>
                <v-row>
                  <v-col cols="3">
                    فروشنده: {{ ticket.admin.name }}
                  </v-col>
                  <v-col cols="3">
                    شماره همراه : {{ ticket.admin.mobile }}
                  </v-col>
                  <v-col cols="3">
                    ایمیل : {{ ticket.admin.email }}
                  </v-col>
                  <v-col cols="3">
                    کد ملی : {{ ticket.admin.national_code }}
                  </v-col>
                  <v-col cols="3">
                    وضعیت : {{ ticket.admin.status }}
                  </v-col>
                  <v-col cols="3">
                    وضعیت تطابق کد ملی و شماره همراه : {{ ticket.admin.match_mobile_with_national_code }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="ticket.product_name" class="card-show mt-4">
              <v-card-text>
                <v-row>
                  <v-col cols="3">
                    محصول: {{ ticket.product.label }}
                  </v-col>
                  <v-col cols="3">
                    کد محصول : {{ ticket.product.code }}
                  </v-col>
                  <v-col cols="3">
                    وضعیت محصول : {{ ticket.product.status }}
                  </v-col>
                  <v-col cols="3">
                    دسته بندی : {{ ticket.product.category_name }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="ticket.transaction_id" class="card-show mt-4">
              <v-card-text>
                <v-row>
                  <v-col cols="3">
                    تاریخ تراکنش: {{ ticket.transaction.created_at_fa }}
                  </v-col>
                  <v-col cols="3">
                    کد تراکنش : {{ ticket.transaction.arkapay_tracking_code }}
                  </v-col>
                  <v-col cols="3">
                    وضعیت تراکنش : {{ ticket.transaction.status }}
                  </v-col>
                  <v-col cols="3">
                    نوع تراکنش : {{ ticket.transaction.type }}
                  </v-col>
                  <v-col cols="3">
                    شناسه تراکنش : {{ ticket.transaction.transaction_id }}
                  </v-col>
                  <v-col cols="3">
                    توضیحات تراکنش : {{ ticket.transaction.description }}
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>


import TicketComponent from "@/components/Ticket/TicketComponent.vue";

export default {
  name: "TicketGetView",
  components: {TicketComponent},
  data() {
    return {
      loading: true,
      ticket: {},
      response: {
        content: null
      }
    }
  },
  mounted() {
    this.getEntity()
  },
  methods: {
    getEntity() {
      window.axios.get('admin/ticket/get/' + this.$route.params.id).then((res) => {
        this.ticket = res.data.data
        this.loading = false
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#F1416C')
      })
    },
    answer() {
      window.axios.post('admin/ticket/answer/' + this.$route.params.id, {
        content: this.response.content
      }).then((res) => {
        this.getEntity()
        this.openSnackbar(res.data.message, '#413b3b')
        this.loading = false
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#F1416C')
      })
    }
  }
}
</script>

<style>
.card-show {
  border-radius: 10px;
  border-color: #FFD700 !important;
  border-style: solid !important;
}
</style>