<template>
  <div v-if="!loading">
    <v-card class="card-show" color="white">
      <v-card-title>
        ساخت استان جدید
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn width="200px" class="main-button" @click="$router.push('/state/index')">
              <v-icon color="white">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="state.name" label="نام استان" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field v-model="state.label" label="لیبل استان" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-text-field type="number" v-model="state.send_package_price" label="هزینه ارسال بسته به این استان" color="black">
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <v-switch :true-value="1" :false-value="0" v-model="state.is_active" label="وضعیت استان" color="black">
            </v-switch>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="create()" class="letter-spacing-normal white--text main-button" width="200px">ساخت
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "StateCreateView",
  data() {
    return {
      loading: false,
      state: {
        name: null,
        label: null,
        is_active: null,
        send_package_price: null,
      },
    }
  },
  mounted() {
  },
  methods: {
    create() {
      window.axios.post('admin/state/create/', {
        name: this.state.name,
        label: this.state.label,
        is_active: this.state.is_active,
        send_package_price: this.state.send_package_price,
      }).then((res) => {
        this.openSnackbar(res.data.message, '#413b3b')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err, '#F1416C')
        } else if (err.response.status === 400 || err.response.status === 404) {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  }
}
</script>

<style>
.main-margin {
  margin: 2vh 6vw 2vh 2vw;
}

.main-padding {
  padding: 1vh 2vw;
}

.text-dialog-title {
  font-size: 18px;
  font-weight: 800;
}

.letter-spacing-normal {
  letter-spacing: normal;
}
</style>