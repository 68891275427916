<template>
  <div class="main-margin" v-if="!loading">
    <v-card class="main-padding" color="white">
      <v-card-title>
        ویرایش فروشگاه
      </v-card-title>
      <v-card-subtitle>
        پر کردن فیلد های ستاره دار <span class="error--text">*</span> الزامی است
      </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="text-left">
            <v-btn width="200px" class="main-button" @click="$router.push('/seller/index')">
              <v-icon class="main-icon">mdi-arrow-left</v-icon>
            </v-btn>
          </v-col>

          <v-col cols="12">
            <div class="mb-4 text-dialog-title">
              اطلاعات نمایشی
            </div>
            <v-row>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" v-model="detail.shop_name" label="نام فروشگاه" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" v-model="detail.brand_name"
                              hint="در صورت عدم وجود،  مجددا اسم فروشگاه را وارد کنید."
                              label="نام برند" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field :rules="$rules.required" v-model="detail.shop_expert_short_description"
                              label="توضیحات مختصر درباره تخصص فروشگاه" color="black"
                              hint="به عنوان مثال :دارای بیش از ۱ دهه سابقه در طلاسازی ، فروش سرویس های طلا ، سکه و دلار.">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" v-model="detail.shop_expert_title" hint="تیتر تخصص مانند طلاساز."
                              label="تیتر تخصص اصلی" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="6">
                <vue-persian-datetime-picker color="black" v-model="detail.active_from"
                                             type="date"
                                             custom-input="#activeFrom"
                                             auto-submit></vue-persian-datetime-picker>
                <v-text-field
                    :rules="$rules.required"
                    label="تاریخ شروع کار فروشگاه خود را وارد کنید.(به عنوان سابقه فروشگاه به مشتریان نمایش داده می شود)"
                    v-model="detail.active_from" id="activeFrom"
                    color="black">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <b>توضیحات کامل درباره فروشگاه <span class="error--text">*</span></b>
                <ckeditor :editor-url="$ckeditor.editorUrl" v-model="detail.shop_expert_description" color="black">
                </ckeditor>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="my-3">
            <div class="mb-4 text-dialog-title">
              اطلاعات ثبتی
            </div>
            <v-row>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" v-model="detail.manager_name" label="نام مدیر فروشگاه"
                              color="black">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" v-model="detail.manager_national_code"
                              label="کد ملی مدیر فروشگاه" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field :rules="$rules.required" v-model="detail.economic_code" label="کد اقتصادی فروشگاه"
                              color="black">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field :rules="$rules.required" v-model="detail.submit_number" label="شماره ثبت فروشگاه"
                              color="black">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-select :rules="$rules.required" :items="[{text:'مالک',value:'owner'},{text:'مستاجر',value:'rent'}]"
                          v-model="detail.ownership_type"
                          label="نوع مالکیت فروشگاه" color="black">
                </v-select>
              </v-col>
            </v-row>
          </v-col>


          <v-col cols="12" class="my-3">
            <div class="mb-4 text-dialog-title">
              شماره تماس
            </div>
            <v-row>
              <v-col cols="2">
                <v-text-field hint="در صورت تیک زدن نمایش  این فیلد باید پر شود." v-model="detail.shop_phone_number"
                              label="شماره ثابت فروشگاه" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-checkbox :true-value="1" :false-value="0" v-model="detail.visibility_shop_phone_number"
                            label="شماره ثابت نمایش داده شود؟" color="black">
                </v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-text-field hint="در صورت تیک زدن نمایش  این فیلد باید پر شود." v-model="detail.shop_mobile"
                              label="شماره موبایل فروشگاه" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-checkbox :true-value="1" :false-value="0" v-model="detail.visibility_shop_mobile"
                            label="شماره موبایل نمایش داده شود؟" color="black">
                </v-checkbox>
              </v-col>

              <v-col cols="2">
                <v-text-field hint="در صورت تیک زدن نمایش  این فیلد باید پر شود." v-model="detail.shop_second_mobile"
                              label="شماره موبایل دوم فروشگاه" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-checkbox :true-value="1" :false-value="0" v-model="detail.visibility_shop_second_mobile"
                            label="شماره موبایل دوم نمایش داده شود؟" color="black">
                </v-checkbox>
              </v-col>
            </v-row>
          </v-col>


          <v-col cols="12">
            <div class="mb-4 text-dialog-title">
              آدرس
            </div>
            <v-row>
              <v-col cols="4">
                <v-autocomplete
                    :rules="$rules.required"
                    color="black" v-model="detail.state_id"
                    :items="states"
                    item-text="label"
                    label="فروشگاه در چه استانی قرار دارد؟" item-value="id"
                    @change="getCities"></v-autocomplete>
              </v-col>

              <v-col cols="4" v-if="detail.state_id">
                <v-autocomplete
                    :rules="$rules.required"
                    color="black" v-model="detail.city_id"
                    :items="cities"
                    item-text="label"
                    label="فروشگاه در چه شهری قرار دارد؟" item-value="id"></v-autocomplete>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    :rules="$rules.required"
                    v-model="detail.shop_postal_code"
                    label="کد پستی فروشگاه" color="black">
                </v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    :rules="$rules.required"
                    v-model="detail.shop_address" label="آدرس کامل فروشگاه" color="black"
                    hint="با اسم استان و شهر وارد شود.">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>


          <v-col cols="12">
            <div class="mb-4 text-dialog-title">
              اطلاعات بانکی
            </div>
            <v-row>
              <v-col cols="7">
                <v-text-field :rules="$rules.required" v-model="detail.bank_sheba_number"
                              label="شماره شبا" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field :rules="$rules.required" v-model="detail.bank_card_number"
                              label="شماره کارت" color="black">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <div class="mb-4 text-dialog-title">
              شبکه های اجتماعی
            </div>
            <v-row>
              <v-col cols="4">
                <v-text-field v-model="detail.social_media_instagram"
                              label="آدرس اینستاگرام" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="detail.social_media_telegram"
                              label="آدرس تلگرام" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="detail.social_media_whatsapp"
                              label="آدرس واتساپ" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="detail.social_media_x"
                              label="آدرس ایکس (توییتر سابق)" color="black">
                </v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="detail.social_media_eitaa"
                              label="آدرس ایتا" color="black">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">

            <div class="mb-4 text-dialog-title">
              زمان خدمات رسانی
            </div>
            <v-row>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" v-model="detail.works_hour_from" label="ساعت باز شدن فروشگاه"
                              hint="بین ۰ تا ۲۴ وارد شود"
                              color="black">
                </v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" v-model="detail.works_hour_to" label="ساعت بسته شدن فروشگاه"
                              hint="بین ۰ تا ۲۴ وارد شود"
                              color="black">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12">
            <div class="mb-4 text-dialog-title">
              تصاویر و مستندات
            </div>
            <v-row>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" label="لوگوی فروشگاه"
                              @click="shop_logo_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.shop_logo"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="نمونه امضا/مهر فروشگاه جهت استفاده در فاکتور"
                              @click="shop_sign_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.shop_sign"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field :rules="$rules.required" label="جواز کسب"
                              @click="shop_certificate_work_permit_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.shop_certificate_work_permit"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="مستندات مالکیت ملک(سند/اجاره نامه)"
                              @click="ownership_document_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.ownership_document"></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-text-field label="مستندات/گواهی نامه/تاییدیه اضافه (۱)"
                              @click="shop_extra_certificate_one_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.shop_extra_certificate_one"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="مستندات/گواهی نامه/تاییدیه اضافه (۲)"
                              @click="shop_extra_certificate_two_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.shop_extra_certificate_two"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="مستندات/گواهی نامه/تاییدیه اضافه (۳)"
                              @click="shop_extra_certificate_three_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.shop_extra_certificate_three"></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="مستندات/گواهی نامه/تاییدیه اضافه (۴)"
                              @click="shop_extra_certificate_four_dialog = true" prepend-icon="mdi-plus"
                              color="black"
                              v-model="detail.shop_extra_certificate_four"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="edit()" class="letter-spacing-normal white--text main-button" width="200px">ویرایش
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="shop_logo_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader" @closeUploadFileDialog="shop_logo_dialog = false"
                     @changeFile="detail.shop_logo = $event"></drag-and-drop>
    </v-dialog>
    <v-dialog v-model="shop_sign_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader" @closeUploadFileDialog="shop_sign_dialog = false"
                     @changeFile="detail.shop_sign = $event"></drag-and-drop>
    </v-dialog>
    <v-dialog v-model="shop_certificate_work_permit_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader"
                     @closeUploadFileDialog="shop_certificate_work_permit_dialog = false"
                     @changeFile="detail.shop_certificate_work_permit = $event"></drag-and-drop>
    </v-dialog>
    <v-dialog v-model="ownership_document_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader" @closeUploadFileDialog="ownership_document_dialog = false"
                     @changeFile="detail.ownership_document = $event"></drag-and-drop>
    </v-dialog>
    <v-dialog v-model="shop_extra_certificate_one_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader"
                     @closeUploadFileDialog="shop_extra_certificate_one_dialog = false"
                     @changeFile="detail.shop_extra_certificate_one = $event"></drag-and-drop>
    </v-dialog>
    <v-dialog v-model="shop_extra_certificate_two_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader"
                     @closeUploadFileDialog="shop_extra_certificate_two_dialog = false"
                     @changeFile="detail.shop_extra_certificate_two = $event"></drag-and-drop>
    </v-dialog>
    <v-dialog v-model="shop_extra_certificate_three_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader"
                     @closeUploadFileDialog="shop_extra_certificate_three_dialog = false"
                     @changeFile="detail.shop_extra_certificate_three = $event"></drag-and-drop>
    </v-dialog>
    <v-dialog v-model="shop_extra_certificate_four_dialog" max-width="50vw">
      <drag-and-drop module="articles" ref="file-uploader"
                     @closeUploadFileDialog="shop_extra_certificate_four_dialog = false"
                     @changeFile="detail.shop_extra_certificate_four = $event"></drag-and-drop>
    </v-dialog>
  </div>
</template>

<script>

import DragAndDrop from "@/components/UploadFile/DragAndDrop.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  name: "SellerShopEditView",
  components: {DragAndDrop, VuePersianDatetimePicker},
  data() {
    return {
      loading: true,
      shop_logo_dialog: false,
      shop_sign_dialog: false,
      shop_certificate_work_permit_dialog: false,
      ownership_document_dialog: false,
      shop_extra_certificate_one_dialog: false,
      shop_extra_certificate_two_dialog: false,
      shop_extra_certificate_three_dialog: false,
      shop_extra_certificate_four_dialog: false,
      shop_extra_certificate_five_dialog: false,
      shop_extra_certificate_six_dialog: false,
      states: [],
      cities: [],
      seller: {
        name: null,
        mobile: null,
        avatar: null,
        email: null,
        password: null,
        password_confirmation: null,
        status: null,
      },
      detail: {
        shop_name: null,
        shop_phone_number: null,
        visibility_shop_phone_number: 1,
        shop_mobile: null,
        visibility_shop_mobile: 1,
        shop_second_mobile: null,
        visibility_shop_second_mobile: 1,
        shop_address: null,
        state_id: null,
        city_id: null,
        shop_postal_code: null,
        bank_sheba_number: null,
        bank_card_number: null,
        manager_name: null,
        manager_national_code: null,
        economic_code: null,
        submit_number: null,
        brand_name: null,
        social_media_instagram: null,
        social_media_telegram: null,
        social_media_whatsapp: null,
        social_media_x: null,
        social_media_eitaa: null,
        works_hour_from: null,
        works_hour_to: null,
        shop_expert_short_description: null,
        shop_expert_description: null,
        shop_expert_title: null,
        active_from: null,
        shop_logo: null,
        shop_sign: null,
        shop_certificate_work_permit: null,
        ownership_document: null,
        shop_extra_certificate_one: null,
        shop_extra_certificate_two: null,
        shop_extra_certificate_three: null,
        shop_extra_certificate_four: null,
        shop_extra_certificate_five: null,
        shop_extra_certificate_six: null,
        ownership_type: null,
      }
    }
  },
  mounted() {
    this.getEntity()
    this.getStates()
  },
  methods: {
    getCities() {
      window.axios.get('admin/city/state/index/' + this.detail.state_id).then((res) => {
        this.cities = res.data.data
      })
    },
    getStates() {
      window.axios.get('admin/state/index?per_page=100').then((res) => {
        this.states = res.data.data.data
      })
    },
    getEntity() {
      window.axios.get('admin/seller/get/' + this.$route.params.id).then((res) => {
        this.seller = res.data.data
        if (res.data.data.detail) {
          this.detail = res.data.data.detail
          this.detail.active_from = this.detail.active_from_fa
          if (this.detail.city_id) {
            this.getCities()
          }
        }
        this.loading = false
      }).catch((err) => {
        this.openSnackbar(err.response.data.message, '#F1416C')
      })
    },
    edit() {
      window.axios.post('admin/seller/update-detail/' + this.$route.params.id, this.detail).then((res) => {
        this.openSnackbar(res.data.message, '#413b3b')
      }).catch((err) => {
        if (err.response.status === 422) {
          this.showValidationErrors(err, '#F1416C')
        } else if (err.response.status === 400 || err.response.status === 404) {
          this.openSnackbar(err.response.data.message, '#F1416C')
        }
      })
    },
  }
}
</script>

<style>
.main-margin {
  margin: 2vh 6vw 2vh 2vw;
}

.main-padding {
  padding: 1vh 2vw;
}

.text-dialog-title {
  font-size: 18px;
  font-weight: 800;
}

.letter-spacing-normal {
  letter-spacing: normal;
}
</style>