import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from "@/views/Home/HomeView";
import LoginView from "@/views/Auth/LoginView";

import CategoryIndexView from "@/views/Category/CategoryIndexView";
import CategoryCreateView from "@/views/Category/CategoryCreateView";
import CategoryEditView from "@/views/Category/CategoryEditView";

import TagIndexView from "@/views/Tag/TagIndexView";
import TagCreateView from "@/views/Tag/TagCreateView";
import TagEditView from "@/views/Tag/TagEditView";


import QuestionIndexView from "@/views/Question/QuestionIndexView";
import ContactUsIndexView from "@/views/ContactUs/ContactUsIndexView";
import SettingIndexView from "@/views/Setting/SettingIndexView";
import QuestionEditView from "@/views/Question/QuestionEditView";

import UserIndexView from "@/views/User/UserIndexView.vue";
import UserCreateView from "@/views/User/UserCreateView.vue";
import UserEditView from "@/views/User/UserEditView.vue";
import UserGetView from "@/views/User/UserGetView.vue";
import AdminIndexView from "@/views/Admin/AdminIndexView.vue";
import AdminCreateView from "@/views/Admin/AdminCreateView.vue";
import AdminEditView from "@/views/Admin/AdminEditView.vue";
import AdminGetView from "@/views/Admin/AdminGetView.vue";
import SellerIndexView from "@/views/Seller/SellerIndexView.vue";
import SellerCreateView from "@/views/Seller/SellerCreateView.vue";
import SellerEditView from "@/views/Seller/SellerEditView.vue";
import SellerGetView from "@/views/Seller/SellerGetView.vue";
import MaterialIndexView from "@/views/Material/MaterialIndexView.vue";
import MaterialCreateView from "@/views/Material/MaterialCreateView.vue";
import MaterialEditView from "@/views/Material/MaterialEditView.vue";
import MaterialGetView from "@/views/Material/MaterialGetView.vue";
import ProductIndexView from "@/views/Product/ProductIndexView.vue";
import ProductCreateView from "@/views/Product/ProductCreateView.vue";
import ProductEditView from "@/views/Product/ProductEditView.vue";
import ProductGetView from "@/views/Product/ProductGetView.vue";
import TransactionIndexView from "@/views/Transaction/TransactionIndexView.vue";
import RoleCreateView from "@/views/Role/RoleCreateView.vue";
import RoleEditView from "@/views/Role/RoleEditView.vue";
import RoleIndexView from "@/views/Role/RoleIndexView.vue";
import RuleIndexView from "@/views/Rule/RuleIndexView.vue";
import RuleGetView from "@/views/Rule/RuleGetView.vue";
import RuleEditView from "@/views/Rule/RuleEditView.vue";
import ContactUsGetView from "@/views/ContactUs/ContactUsGetView.vue";
import StateIndexView from "@/views/State/StateIndexView.vue";
import StateCreateView from "@/views/State/StateCreateView.vue";
import StateEditView from "@/views/State/StateEditView.vue";
import CityIndexView from "@/views/City/CityIndexView.vue";
import CityCreateView from "@/views/City/CityCreateView.vue";
import CityEditView from "@/views/City/CityEditView.vue";
import CommentIndexView from "@/views/Comment/CommentIndexView.vue";
import CommentCreateView from "@/views/Comment/CommentCreateView.vue";
import LikeIndexView from "@/views/Like/LikeIndexView.vue";
import LikeCreateView from "@/views/Like/LikeCreateView.vue";
import TicketIndexView from "@/views/Ticket/TicketIndexView.vue";
import TicketCreateView from "@/views/Ticket/TicketCreateView.vue";
import TicketMyView from "@/views/Ticket/TicketMyView.vue";
import TicketGetView from "@/views/Ticket/TicketGetView.vue";
import CurrencyIndexView from "@/views/Currency/CurrencyIndexView.vue";
import CurrencyEditView from "@/views/Currency/CurrencyEditView.vue";
import CurrencyCreateView from "@/views/Currency/CurrencyCreateView.vue";
import CurrencyHistoryView from "@/views/Currency/CurrencyHistoryView.vue";
import QuestionCreateView from "@/views/Question/QuestionCreateView.vue";
import InquiryIndexView from "@/views/Inquiry/InquiryIndexView.vue";
import InquiryCreateView from "@/views/Inquiry/InquiryCreateView.vue";
import InquiryView from "@/views/Inquiry/InquiryView.vue";
import DetailIndexView from "@/views/Detail/DetailIndexView.vue";
import DetailEditView from "@/views/Detail/DetailEditView.vue";
import SellerShopEditView from "@/views/Seller/SellerShopEditView.vue";
import OrderIndexView from "@/views/Order/OrderIndexView.vue";
import OrderGetView from "@/views/Order/OrderGetView.vue";
import OrderRequestView from "@/views/Order/OrderRequestView.vue";


Vue.use(VueRouter)

const routes = [
    /*home*/
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    /*login*/
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },

    /*User*/
    {
        path: '/user/index',
        name: 'UserIndexView',
        component: UserIndexView
    },
    {
        path: '/user/create',
        name: 'UserCreateView',
        component: UserCreateView
    },
    {
        path: '/user/edit/:id',
        name: 'UserEditView',
        component: UserEditView
    },
    {
        path: '/user/get/:id',
        name: 'UserGetView',
        component: UserGetView
    },


    /*Admin*/
    {
        path: '/admin/index',
        name: 'AdminIndexView',
        component: AdminIndexView
    },
    {
        path: '/admin/create',
        name: 'AdminCreateView',
        component: AdminCreateView
    },
    {
        path: '/admin/edit/:id',
        name: 'AdminEditView',
        component: AdminEditView
    },
    {
        path: '/admin/get/:id',
        name: 'AdminGetView',
        component: AdminGetView
    },

    /*Seller*/
    {
        path: '/seller/index',
        name: 'SellerIndexView',
        component: SellerIndexView
    },
    {
        path: '/seller/create',
        name: 'SellerCreateView',
        component: SellerCreateView
    },
    {
        path: '/seller/edit/:id',
        name: 'SellerEditView',
        component: SellerEditView
    },
    {
        path: '/seller/shop/edit/:id',
        name: 'SellerShopEditView',
        component: SellerShopEditView
    },
    {
        path: '/seller/get/:id',
        name: 'SellerGetView',
        component: SellerGetView
    },


    /*GoldSmith*/
    {
        path: '/user/index',
        name: 'UserIndexView',
        component: UserIndexView
    },
    {
        path: '/user/create',
        name: 'UserCreateView',
        component: UserCreateView
    },
    {
        path: '/user/edit/:id',
        name: 'UserEditView',
        component: UserEditView
    },

    /*Material*/
    {
        path: '/material/index',
        name: 'MaterialIndexView',
        component: MaterialIndexView
    },
    {
        path: '/material/create',
        name: 'MaterialCreateView',
        component: MaterialCreateView
    },
    {
        path: '/material/edit/:id',
        name: 'MaterialEditView',
        component: MaterialEditView
    },
    {
        path: '/material/get/:id',
        name: 'MaterialGetView',
        component: MaterialGetView
    },

    /*Product*/
    {
        path: '/product/index',
        name: 'ProductIndexView',
        component: ProductIndexView
    },
    {
        path: '/product/create',
        name: 'ProductCreateView',
        component: ProductCreateView
    },
    {
        path: '/product/edit/:id',
        name: 'ProductEditView',
        component: ProductEditView
    },
    {
        path: '/product/get/:id',
        name: 'ProductGetView',
        component: ProductGetView
    },


    /*category*/
    {
        path: '/category/index',
        name: 'CategoryIndexView',
        component: CategoryIndexView
    },
    {
        path: '/category/create',
        name: 'CategoryCreateView',
        component: CategoryCreateView
    },
    {
        path: '/category/edit/:id',
        name: 'CategoryEditView',
        component: CategoryEditView
    },

    /*order*/
    {
        path: '/order/index',
        name: 'OrderIndexView',
        component: OrderIndexView
    },
    {
        path: '/order/requests',
        name: 'OrderRequestIndex',
        component: OrderRequestView
    },
    {
        path: '/order/get/:id',
        name: 'OrderGetView',
        component: OrderGetView
    },
    {
        path: '/category/edit/:id',
        name: 'CategoryEditView',
        component: CategoryEditView
    },

    /*tag*/
    {
        path: '/tag/index',
        name: 'TagIndexView',
        component: TagIndexView
    },
    {
        path: '/tag/create',
        name: 'TagCreateView',
        component: TagCreateView
    },
    {
        path: '/tag/edit/:id',
        name: 'TagEditView',
        component: TagEditView
    },

    /*role*/
    {
        path: '/role/index',
        name: 'RoleIndexView',
        component: RoleIndexView
    },
    {
        path: '/role/create',
        name: 'RoleCreateView',
        component: RoleCreateView
    },
    {
        path: '/role/edit/:id',
        name: 'RoleEditView',
        component: RoleEditView
    },


    /*rule*/
    {
        path: '/rule/index',
        name: 'RoleIndexView',
        component: RuleIndexView
    },
    {
        path: '/rule/get/:id',
        name: 'RuleGetView',
        component: RuleGetView
    },
    {
        path: '/rule/edit/:id',
        name: 'RuleEditView',
        component: RuleEditView
    },

    /*question*/
    {
        path: '/question/index',
        name: 'QuestionIndexView',
        component: QuestionIndexView
    },
    {
        path: '/question/create',
        name: 'QuestionCreateView',
        component: QuestionCreateView
    },
    {
        path: '/question/edit/:id',
        name: 'QuestionEditView',
        component: QuestionEditView
    },
    /*transaction*/
    {
        path: '/transaction/index',
        name: 'TransactionIndexView',
        component: TransactionIndexView
    },
    /*contactUs*/
    {
        path: '/contact-us/index',
        name: 'ContactUsIndexView',
        component: ContactUsIndexView
    },
    {
        path: '/contact-us/get/:id',
        name: 'ContactUsGetView',
        component: ContactUsGetView
    },
    /*setting*/
    {
        path: '/setting/index',
        name: 'SettingIndexView',
        component: SettingIndexView
    },
    /*state*/
    {
        path: '/state/index',
        name: 'StateIndexView',
        component: StateIndexView
    },
    {
        path: '/state/create',
        name: 'StateCreateView',
        component: StateCreateView
    },
    {
        path: '/state/edit/:id',
        name: 'StateEditView',
        component: StateEditView
    },
    /*city*/
    {
        path: '/city/index',
        name: 'CityIndexView',
        component: CityIndexView
    },
    {
        path: '/city/create',
        name: 'CityCreateView',
        component: CityCreateView
    },
    {
        path: '/city/edit/:id',
        name: 'CityEditView',
        component: CityEditView
    },

    /*Comment*/
    {
        path: '/comment/index',
        name: 'CommentIndexView',
        component: CommentIndexView
    },
    {
        path: '/comment/create',
        name: 'CommentCreateView',
        component: CommentCreateView
    },

    /*Like*/
    {
        path: '/like/index',
        name: 'LikeIndexView',
        component: LikeIndexView
    },
    {
        path: '/like/create',
        name: 'LikeCreateView',
        component: LikeCreateView
    },

    /*Ticket*/
    {
        path: '/ticket/index',
        name: 'TicketIndexView',
        component: TicketIndexView
    },
    {
        path: '/ticket/my',
        name: 'TicketMyView',
        component: TicketMyView
    },
    {
        path: '/ticket/get/:id',
        name: 'TicketGetView',
        component: TicketGetView
    },
    {
        path: '/ticket/create',
        name: 'TicketCreateView',
        component: TicketCreateView
    },

    /*currencies*/
    {
        path: '/currency/index',
        name: 'CurrencyIndexView',
        component: CurrencyIndexView
    },
    {
        path: '/currency/edit/:id',
        name: 'CurrencyEditView',
        component: CurrencyEditView
    },
    {
        path: '/currency/history/:id',
        name: 'CurrencyHistoryView',
        component: CurrencyHistoryView
    },
    {
        path: '/currency/create',
        name: 'CurrencyCreateView',
        component: CurrencyCreateView
    },


    /*inquiry*/
    {
        path: '/inquiry/index',
        name: 'InquiryIndexView',
        component: InquiryIndexView
    },
    {
        path: '/inquiry/create',
        name: 'InquiryCreateView',
        component: InquiryCreateView
    },
    {
        path: '/inquiry/get/:id',
        name: 'InquiryView',
        component: InquiryView
    },

    /*Detail*/
    /*inquiry*/
    {
        path: '/detail/index',
        name: 'DetailIndexView',
        component: DetailIndexView
    },
    {
        path: '/detail/edit/:id',
        name: 'DetailEditView',
        component: DetailEditView
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
