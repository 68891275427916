<template>
  <v-navigation-drawer
      class="side-bar"
      color="#f7f8fa"
      width="20vw"
      right
      mini-variant-width="5vw"
      permanent
      expand-on-hover
      fixed
      v-model="drawer"
      flat
  >
    <v-list
        nav
    >
      <v-list-item @click="$router.push('/')">
        <v-list-item-icon>
        <v-icon x-large class="menu-icon">mdi-view-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          داشبورد اصلی
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/user/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          مدیریت کاربران
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/seller/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-account-check</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          مدیریت فروشندگان
        </v-list-item-title>
      </v-list-item>
<!--      <v-list-item @click="$router.push('/goldsmith/index')">-->
<!--        <v-list-item-icon>-->
<!--          <v-icon class="menu-icon" x-large>mdi-account-cog</v-icon>-->
<!--        </v-list-item-icon>-->
<!--        <v-list-item-title class="menu-text">-->
<!--          مدیریت طلاسازان-->
<!--        </v-list-item-title>-->
<!--      </v-list-item>-->
      <v-list-item @click="$router.push('/admin/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-account-hard-hat</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          مدیریت ادمین ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/role/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-alert-octagon-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          مدیریت نقش ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/currency/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-gold</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          واحد های مالی
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/material/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-hexagon-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          متریال
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/category/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-shape-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
           دسته بندی ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/tag/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-square-rounded-badge-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          تگ ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/product/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-plus-box-multiple-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          محصولات
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/ticket/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-email-alert-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          تیکت ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/ticket/my')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-email-alert</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          تیکت های من
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/order/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-archive-clock-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          سفارشات
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/order/requests')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-diamond-stone</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          درخواست های جواهر
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/comment/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-comment-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          کامنت ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/like/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-thumb-up-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          لایک ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/transaction/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-bank-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          تراکنش ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/log/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-chart-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          گزارشات
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/question/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-tooltip-question-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          سوالات
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/contact-us/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-phone-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          فرم های تماس با ما
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/rule/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-police-badge</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          قوانین و مقررات
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/detail/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-monitor-screenshot</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          محتوای سایت
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/setting/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-cog-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          تنظیمات
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/state/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-city-variant-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          استان ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/city/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-home-city-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
          شهر ها
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="$router.push('/inquiry/index')">
        <v-list-item-icon>
          <v-icon class="menu-icon" x-large>mdi-account-question-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title class="menu-text">
         استعلام
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
export default {
  name: "NavbarComponent",
  data() {
    return {
      drawer: false,
    }
  },
  methods: {
    goTo() {
    },
    logout() {

    }
  }
}
</script>

<style scoped>
.side-bar{
  border-radius: 10px;
  //border-style: solid;
  //border-width: 1px!important;
  //border-color: #FFD700!important;
  box-shadow: 0px 0px 10px 10px rgba(255, 215, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(236, 185, 31, 0.12)!important;
}
.side-bar:hover{
  border-radius: 10px;
  //border-style: solid;
  //border-width: 1px!important;
  //border-color: #FFD700!important;
  box-shadow: 0px 0px 100px 10px rgba(255, 215, 0, 0.6), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(236, 185, 31, 0.12)!important;
}
.menu-text {
  color: #413b3b !important;
  font-size: 18px !important;
}
.menu-icon{
  color: #413b3b !important;
}
.menu-icon:hover{
  color:  #FFD700!important;
}
</style>