<template>
  <div class="main-page">
    <v-card elevation="0" color="white">
      <v-row>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-image-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-video-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-video-account</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-handshake-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-message-question-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-post-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-post-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card elevation="0" class="main-page-card">
            <v-card-text class="white--text">
              <v-icon large color="#FFD700;">mdi-post-outline</v-icon>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="chart-container" v-if="!chartLoading">
          نمودار تغییر یک ماهه طلا ۱۸ عیار گرمی
          <finance-line-chart :dataset="gold"></finance-line-chart>
        </v-col>
        <v-col cols="12" class="chart-container" v-if="!chartLoading">
          نمودار تغییر یک ماهه دلار
          <finance-line-chart :dataset="dollar"></finance-line-chart>
        </v-col>
        <v-col cols="12" class="chart-container" v-if="!chartLoading">
          نمودار تغییر یک ماهه سکه امامی
          <finance-line-chart :dataset="coin"></finance-line-chart>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import FinanceLineChart from "@/components/Charts/FinanceLineChart.vue";

export default {
  name: "HomeView",
  components: {FinanceLineChart},
  data() {
    return {
      chartLoading: true,
      dollar: {
        labels: [],
        datasets: [
          {
            label: 'قیمت دلار',
            backgroundColor: '#0a04f6',
            borderColor: '#446bff',
            data: []
          },
        ]
      },
      coin: {
        labels: [],
        datasets: [
          {
            label: 'قیمت سکه امامی',
            backgroundColor: '#ffcd05',
            borderColor: '#ffdd00',
            data: []
          },
        ]
      },
      gold: {
        labels: [],
        datasets: [
          {
            label: 'قیمت طلا گرمی',
            backgroundColor: '#ffdd00',
            borderColor: '#000000',
            data: []
          },
        ]
      },
      homePageData: []
    }
  },
  methods: {
    getHomePageData() {
      window.axios.get('/admin/backoffice/homepage').then((res) => {
        this.dollar.labels = res.data.data.charts.dollar.labels
        this.dollar.datasets[0].data = res.data.data.charts.dollar.data

        this.coin.labels = res.data.data.charts.coin.labels
        this.coin.datasets[0].data = res.data.data.charts.coin.data

        this.gold.labels = res.data.data.charts.gold.labels
        this.gold.datasets[0].data = res.data.data.charts.gold.data
        this.chartLoading = false
      })
    }
  },
  mounted() {
    this.getHomePageData()
  }
}
</script>

<style scoped>

.main-page {
  background-color: #f7f8fa;
}

.main-page-card {
  background-color: #f7f8fa;
  border-style: solid;
  border-color: #FFD700;
}

.main-page-card:hover {
  border-color: #413b3b;
}
</style>